<template>
  <base-toggle
    v-bind="{ value, disabled, required }"
    v-model="modelValue"
    class="items-start align-top i-flex"
    :class="[
      size === 'sm' ? 'gap-x-2' : 'gap-x-4',
      { 'cursor-pointer': !disabled },
    ]"
    data-test-id="vf-checkbox"
    @change="$emit('change', $event)"
  >
    <span
      :style="{ 'border-radius': radius?.[size] }"
      class="shrink-0 rounded-sm ring ring-inset duration peer-focus-visible:outline-auto"
      :class="[
        {
          'my-1': size === 'sm',
          [checkboxClasses.default]: !checked && !disabled,
          [checkboxClasses.selected]: checked && !disabled,
          [checkboxClasses.disabled]: !checked && disabled,
          [checkboxClasses.selectedDisabled]: checked && disabled,
          [checkboxClasses.error]: invalid,
        },
      ]"
      data-test-id="vf-checkbox-icon"
    >
      <vf-icon
        name="check"
        :size="size"
        :class="[
          checked ? 'op-100' : 'op-0',
          {
            'c-white': checked && disabled,
          },
        ]"
      />
    </span>
    <span
      v-if="$slots.default"
      class="transition-colors "
      :class="disabled ? (variant ? 'c-grey-60' : 'c-grey-30') : (variant ? 'c-white' : 'c-grey-10')"
      data-test-id="vf-checkbox-label"
    >
      <slot />
      <span v-if="required" aria-hidden="true">*</span>
    </span>
  </base-toggle>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  /**
   * Sets the value of the checkbox
   */
  value?: string
  /**
   * Determines the size of the checkbox and the amount of spacing between checkbox and label
   */
  size?: 'sm' | 'md'
  /**
   * Sets the disabled state of the checkbox
   */
  disabled?: boolean
  /**
   * Sets the invalid state of the checkbox
   */
  invalid?: boolean
  /**
   * Defines if the checkbox input is required for accessibility purposes
   */
  required?: boolean
  /**
   * Defines variant
   */
  variant?: 'inverse'
}>(), {
  size: 'md'
})

defineEmits<{ change: [value: boolean] }>()

const modelValue = defineModel<boolean | string[]>()

const { brandClasses, radius } = useAppConfig().components.vf.checkbox

const checked = computed(() =>
  Array.isArray(modelValue.value) && props.value ? modelValue.value.includes(props.value) : modelValue.value
)

const checkboxClasses = computed(() =>
  brandClasses[props.variant || 'base']
)
</script>
