<template>
  <base-popover v-slot="{ toggle, close, opened }" placement="bottom-start" :offset="-1" data-test-id="vf-dropdown">
    <base-button
      class="relative h-10 flex items-center b b-grey-10 space-x-1"
      :class="[
        brandClasses.button,
        opened ? brandClasses.buttonOpen : brandClasses.buttonClosed,
        classButton,
      ]"
      :aria-expanded="opened"
      :aria-controls="id"
      @click="toggle"
      @keydown.esc="close"
    >
      <slot name="label">
        <span v-if="label" class="pointer-events-none text-sm">{{ label }}</span>
      </slot>
      <vf-icon name="chevron" size="md" dir="down" class="pointer-events-none" />
    </base-button>
    <base-popover-content
      :id
      enter-active-class="transform ease-out"
      enter-from-class="op-0"
      enter-to-class="translate-y-0"
      leave-active-class="transform ease-in"
      leave-from-class="translate-y-0"
      leave-to-class="op-0"
      class="scrollbar-sm z-1 overflow-auto rounded-sm rounded-tl-none bg-white px-4 pb-6 pt-5 shadow-lg ring-grey-70 ring-inset transition"
      style="max-width: 46.5rem; max-height: 26rem;"
      :class="[classContent, brandClasses.popoverContent]"
      :style="styleContent"
      data-test-id="vf-dropdown-content"
      @keydown.esc="close"
    >
      <!-- @slot Slot for dropdown content -->
      <slot />
    </base-popover-content>
  </base-popover>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'

defineProps<{
  /**
   * Label for dropdown trigger
   */
  label?: string
  /**
   * Classes to be applied to content container.
   */
  classContent?: CSSClass
  /**
   * Styles to apply to content container.
   */
  classButton?: CSSClass
  /**
   * Styles to apply to button.
   */
  styleContent?: string | object
}>()

const id = useId()
const { brandClasses } = useAppConfig().components.vf.dropdown
</script>
