<template>
  <base-toggle
    v-bind="{ value, disabled }"
    v-model="modelValue"
    class="flex-col center gap-y-1 i-flex"
    :class="{ 'cursor-pointer': !disabled }"
    data-test-id="vf-color-filter"
  >
    <span
      class="relative flex center rounded-full ring ring-inset peer-focus-visible:outline-auto"
      :class="[
        disabled ? 'bg-cross ring-grey-50' : brandClasses.ring,
        {
          'ring-grey-10': value && Array.isArray(modelValue) && modelValue.includes(value),
        },
      ]"
    >
      <vf-color-swatch
        :color
        :class-inner="[
          'rounded-full',
          brandClasses.swatchInner,
          disabled && brandClasses.swatchInnerDisabled,
        ]"
        :class="[
          brandClasses.swatch,
          disabled && brandClasses.swatchDisabled,
        ]"
      />
      <vf-icon
        v-if="hasIcon"
        name="check"
        size="md"
        class="absolute op-0"
        :class="[
          isLowContrast ? 'c-black' : 'c-white',
          {
            'op-100': value && Array.isArray(modelValue) && modelValue.includes(value),
          },
        ]"
      />
    </span>
    <span
      v-if="$slots.default"
      class="w-full truncate text-center"
      :class="[
        brandClasses.label,
        { 'c-grey-30': disabled },
      ]"
    >
      <!-- @slot Slot for label -->
      <slot />
    </span>
  </base-toggle>
</template>

<script lang="ts" setup>
const { color } = defineProps<{
  /**
   * Sets the value of the color filter
   */
  value?: string
  /**
   * Sets the disabled state of the color filter
   */
  disabled?: boolean
  /**
   * Color value as an array of color codes or 'multicolor'
   */
  color?: string[]
}>()

const modelValue = defineModel<boolean | string[]>()

const { brandClasses, hasIcon } = useAppConfig().components.vf.colorFilter

const isLowContrast = hasHighBrightness((color?.length === 1 && color[0] !== 'multicolor') ? color[0].substring(1) : '')
</script>
