<template>
  <div ref="panel" class="fixed-0 z-overlay flex" :class="[positions[position], { [size === 'lg' ? 'md:px-4' : 'md:px-5']: !isX, 'lg:px-4': !isX }]">
    <base-dialog-root default-open @update:open="$emit('resolve')">
      <base-dialog-overlay
        class="vf-uki69b"
        :style="hideBackdrop ? '' : 'background: #000000bf;'"
        data-test-id="vf-panel-backdrop"
      />
      <vf-dialog-content
        class="vf-vxo7tf"
        :class="[
          transforms[position],
          isX
            ? 'h-full w-70 lg:w-84 p-4'
            : [(size === 'lg' ? 'md:w-full' : 'md:w-2/3'),
               'w-full lg:w-2/3 px-4 pt-2 pb-10 md:px-10 '],
          classContent,
        ]"
        :style="{ [isX ? 'max-width' : 'max-height']: 'calc(100% - 40px)' }"
      >
        <div
          :class="{
            relative: position !== 'bottom',
            flex: position === 'bottom',
          }"
        >
          <div v-if="position === 'bottom'" class="relative m-a w-20 b-b b-grey-50" />
          <base-dialog-close
            v-if="!hideCloseButton"
            :class="{
              'absolute': position !== 'bottom',
              'left-0': position === 'right',
              'right-0': position === 'left',
            }"
            :aria-label="$t.close"
            data-test-id="vf-panel-close"
          >
            <vf-icon name="close" size="md" />
          </base-dialog-close>
        </div>
        <slot />
      </vf-dialog-content>
    </base-dialog-root>
  </div>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'
import type { PositionsSubUnion } from '#types/positions'
import type { SizesSubUnion } from '#types/sizes'

export type Props = {
  /**
   * Size of the panel.
   */
  size?: SizesSubUnion<'sm' | 'lg'>
  /**
   * Position of the panel.
   */
  position?: PositionsSubUnion<'bottom' | 'left' | 'right'>
  /**
   * Classes to be applied to content container.
   */
  classContent?: CSSClass
  /**
   * If backdrop is shown.
   */
  hideBackdrop?: boolean
  /**
   * Whether "close" button is shown.
   */
  hideCloseButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  position: 'right',
  size: 'sm'
})

const emit = defineEmits<{
  resolve: []
}>()

const panel = ref()
const isX = computed(() => ['left', 'right'].includes(props.position))
const transforms = {
  bottom: '[.nested-enter-from_&,.nested-leave-to_&]:translate-y-20',
  left: '[.nested-enter-from_&,.nested-leave-to_&]:-translate-x-20',
  right: '[.nested-enter-from_&,.nested-leave-to_&]:translate-x-20'
}
const positions = {
  bottom: 'items-end justify-center',
  left: 'items-center justify-start',
  right: 'items-center justify-end'
}
const dirs = {
  down: 'bottom',
  left: 'left',
  right: 'right'
}

useSwipe(panel, {
  onSwipeEnd(_, dir) {
    if (dirs[dir.toLocaleLowerCase()] === props.position) emit('resolve')
  }
})
</script>
