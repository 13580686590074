<template>
  <i
    aria-hidden="true"
    class="block shrink-0"
    :class="{ 'transform transition-transform': dir }"
    :style="{
      'background-color': 'currentColor',
      'height': _size,
      'width': _size,
      ...(dir && { transform: `rotate(${dirs[dir]}deg)` }),
      'mask': `url('${path}') ${pos} / contain no-repeat`,
      '-webkit-mask': `url('${path}') ${pos} / contain no-repeat`,
    }"
  />
</template>

<script lang="ts" setup>
import type { IconDir, IconName } from '#types/icon'
import type { IconSizes } from '#types/sizes'
import sprite from '#root/assets/icons.svg'

const props = withDefaults(
  defineProps<{
    name?: IconName
    size?: IconSizes
    dir?: IconDir
    src?: string
    pos?: string
  }>(),
  {
    size: 'sm',
    pos: 'left'
  }
)

const { size: sizes } = useAppConfig().components.vf.icon

if (!props.name && !props.src) console.warn('Icon: missing source!')

const dirs = { right: 0, left: 180, up: -90, down: 90 }
const _size = computed(() => `${sizes[props.size] / 16}rem`)
const path = computed(() => props.src || `${sprite}#${props.name}`)
</script>
