<template>
  <component
    :is="(to && !$attrs.disabled) ? BaseLink : BaseButton"
    :to="!$attrs.disabled ? to : null"
    class="vf-button"
    :class="classes"
    :disabled="!to ? ($attrs.disabled || loading) : null"
    :style="theme ? `--theme: ${theme}` : null"
    data-test-id="vf-button"
  >
    <transition name="fade">
      <vf-loader v-if="loading" class="absolute" style="height: 3.33rem;" />
      <vf-icon v-else-if="success && !loading" name="success" size="lg" class="absolute" />
    </transition>
    <span class="center text-center i-flex" :class="[{ 'op-0': loading || success }, classContent]">
      <slot />
    </span>
  </component>
</template>

<script lang="ts" setup>
import { BaseButton, BaseLink } from '#components'
import { replaceValues } from '#core/utils/object/replaceValues'
import type { CSSClass, Responsive } from '#types/common'
import type { ButtonSize, ButtonVariant } from '#types/components/vf/button'

const props = withDefaults(defineProps<{
  /**
   * Variant of the button which provides color, background, border
   * can be specified for different screen sizes
   */
  variant?: ButtonVariant | Responsive<ButtonVariant>
  /**
   * The size of the button which determines padding and font size
   * can be specified for different screen sizes
   */
  size?: ButtonSize | Responsive<ButtonSize>
  /**
   * Indicates loading state
   */
  loading?: boolean
  /**
   * Indicates success state
   */
  success?: boolean
  /**
   * The same as "to" prop of router-link, turns button into <a> with no change to styling
   */
  to?: string
  /**
   * Style class for button content
   */
  classContent?: CSSClass
  theme?: string
}>(), {
  variant: 'primary',
  size: 'sm'
})

// Some brands won't have certain variants, so we need to replace them with a fallback value
const { fallbackSizes = {}, fallbackVariants = {} } = useAppConfig().components.vf.button || {}

const success = ref(props.success)

const classes = computed(() => [
  getResponsiveClasses(replaceValues(props.variant, fallbackVariants), 'vf-button'),
  getResponsiveClasses(replaceValues(props.size, fallbackSizes), 'vf-button'),
  { relative: success.value || props.loading }
])

whenever(() => !props.loading, () => {
  success.value = true
  setTimeout(() => {
    success.value = false
  }, 750)
})
</script>
