<template>
  <base-dialog-content data-test-id="vf-modal-content" @interact-outside="interactOutside">
    <slot />
  </base-dialog-content>
</template>

<script lang="ts" setup>
defineSlots<{
  default?: () => any
}>()

const { ignoreSelectors } = useAppConfig().components.vf.dialog

/**
 * ignoreSelectors are used to prevent closing modal when clicking on specific elements.
 * Used in cases when we have 2 modals opened at the same time (usually 3rd party + native) and we want to close only one of them.
 * @param event
 */
const interactOutside = (event) => {
  const target = event.target as HTMLElement
  if (target && ignoreSelectors.some((selector) => target.closest(selector)))
    return event.preventDefault()
}
</script>
