<template>
  <component
    :is="to ? BaseLink : BaseButton"
    v-style="{ p: padding, w: width, h: height }"
    :to
    class="group flex center outline-none"
    :aria-current="active"
    data-test-id="vf-color-picker"
  >
    <vf-loader v-if="loading" class="absolute z-overlay h-20" />
    <vf-color-swatch
      v-bind="{ color, unavailable, lazy }"
      :thumbnail="getImageTransformations(thumbnail, config.thumbnail[variant])"
      class="full duration group-focus-visible:outline-auto"
      :class="[
        active ? 'ring ring-grey-10 ring-inset' : 'hover:ring hover:ring-grey-10 hover:ring-inset',
        { 'rounded-full': !isRectangle },
      ]"
      :class-inner="{ 'rounded-full b b-grey-70': !isRectangle }"
      :inset="unavailable ? swatchInsetUnavailable : swatchInset"
    >
      <span v-if="name" class="sr-only">{{ name }}</span>
      <span
        v-if="unavailable"
        v-style:inset="crossInset"
        class="absolute-0 b b-grey-50 bg-cross"
        :class="{ 'rounded-full': !isRectangle }"
        style="background-color: #ffffff99;"
      />
    </vf-color-swatch>
  </component>
</template>

<script lang="ts" setup>
import { BaseButton, BaseLink } from '#components'
import type { Responsive } from '#types/common'
import type { ColorSwatchShape } from '#types/product'
import type { SizesSubUnion } from '#types/sizes'
import type { ColorPickerVariantConfig } from '#types/config/components/vf/colorPicker'

const { size, variant } = withDefaults(
  defineProps<{
    /**
     * ColorPicker will be a link if this prop is provided
     */
    to?: string
    /**
     * Color value as an array of color codes or 'multicolor'
     */
    color?: string[]
    /**
     * Source for color thumbnail image
     */
    thumbnail?: string
    /**
     * Defines the size of the color picker
     */
    size?: SizesSubUnion<'sm' | 'md' | 'lg'> | Responsive<SizesSubUnion<'sm' | 'md' | 'lg'>>
    /**
     * Defines the shape of the color picker
     */
    variant?: ColorSwatchShape
    /**
     * Unavailable means the variant is out of stock, it will appear disabled but remain selectable
     */
    unavailable?: boolean
    /**
     * Whether color is selected
     */
    active?: boolean
    /**
     * Whether to lazy load the thumbnail image
     */
    lazy?: boolean
    /**
     * Whether to lazy load the thumbnail image
     */
    loading?: boolean
    /**
     * Required color name for screen reader
     */
    name?: string
  }>(),
  {
    size: 'md',
    variant: 'round',
    lazy: true
  }
)

const config = useAppConfig().components.vf.colorPicker
const isRectangle = variant === 'rectangle'

const {
  width,
  height,
  padding,
  swatchInset,
  swatchInsetUnavailable,
  crossInset
} = Object.entries(config[variant]).reduce((acc: ColorPickerVariantConfig, [key, val]) => {
  acc[key] = replaceValues<Responsive, Responsive>(isObject(size) ? size : { sm: size }, val)
  return acc
}, {})
</script>
