<template>
  <base-picture
    ref="target"
    v-bind="{ src, alt, height, width }"
    :props-img="{ ...propsImg, class: [{ 'op-0': zoomFactor }, propsImg?.class] }"
    style="transition: background-size 150ms cubic-bezier(0.4, 0, 0.2, 1); transition-timing-function: linear;"
    :style="[
      isHover && {
        '--bg': `url(${_srcZoom})`,
        'cursor': 'zoom-in',
        'background': `var(--bg) ${(x / w) * 100}% ${(y / h) * 100}% / ${(zoomFactor + 1) * 100}%`,
      },
    ]"
    :alt
    data-test-id="vf-zoomer"
    @wheel.stop="onWheel"
    @mouseenter.once="isHover = true"
  />
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import type { Responsive } from '#types/common'

const props = defineProps({
  /**
   * Source of the main image
   */
  src: {
    type: [Object, String] as PropType<Responsive | string>,
    required: true
  },
  /**
   * Source of the zoomed image to be displayed on hover
   */
  srcZoom: {
    type: [Object, String] as PropType<Responsive | string>,
    required: true
  },
  /**
   * Specifies width for various screen sizes
   */
  width: {
    type: [Object, String, Number] as PropType<Responsive | string | number>,
    required: true
  },
  /**
   * Specifies height for various screen sizes
   */
  height: {
    type: [Object, String, Number] as PropType<Responsive | string | number>,
    required: true
  },
  /**
   * Alt text for main image
   */
  alt: String,
  /**
   * Additional props for internal img tag
   */
  propsImg: Object,
  /**
   * Specifies image magnificaiton factor
   */
  maxZoom: Number
})
const target = ref(null)
const zoomFactor = ref(0)
const isHover = ref(false)

const _srcZoom = isObject(props.srcZoom) ? (props.srcZoom.lg || props.srcZoom.md || props.srcZoom.sm) : props.srcZoom

const {
  elementX: x,
  elementY: y,
  elementWidth: w,
  elementHeight: h
} = useMouseInElement(target, { handleOutside: false })

const zoom = (velocity: number) => {
  if (zoomFactor.value === 0) {
    x.value = w.value / 2
    y.value = h.value / 2
  }
  const amount = zoomFactor.value - velocity
  zoomFactor.value = Math.min(Math.max(0, amount), props?.maxZoom || 5)
}

const zoomReset = () => (zoomFactor.value = 0)

const onWheel = ({ deltaY }) => {
  const velocity = deltaY / 500
  requestAnimationFrame(() => zoom(velocity))
}

defineExpose({
  zoomReset,
  zoom,
  zoomFactor
})
</script>
