<template>
  <base-button
    class="group max-w-full center px-2 py-1 i-flex duration"
    :class="config.brandClasses"
    data-test-id="vf-pill"
  >
    <span v-if="$slots.before" style="margin: 0 0.5rem 0 0">
      <slot name="before" />
    </span>
    <span class="truncate text-xs c-grey-20" data-test-id="vf-pill-text">
      <slot />
    </span>
    <span v-if="removable" class="relative" style="margin: 0 0 0 0.5rem" data-test-id="vf-pill-close">
      <vf-icon :name="config.removeIcon" class="absolute group-hocus:op-0" />
      <vf-icon :name="config.removeIconHocused" class="op-0 group-hocus:op-100" />
    </span>
  </base-button>
</template>

<script lang="ts" setup>
defineProps<{
  /**
   * Defines whether close icon is shown or not
   */
  removable?: boolean
}>()

const config = useAppConfig().components.vf.pill
</script>
